
    import Vue from "vue";

    const END_POINT = "/data-audit";

    const auditPurchaseStockTime = (startDate) => Vue.axios.get(END_POINT + "/purchase-stock-time", {params: {start: startDate}});

    const auditInputOutOfRange = (startDate) => Vue.axios.get(END_POINT + "/input-time-out-of-range", {params: {start: startDate}});

    const auditOutputOutOfRange = (startDate) => Vue.axios.get(END_POINT + "/output-time-out-of-range", {params: {start: startDate}});

    const auditOutputStockTimeMismatch = (startDate) => Vue.axios.get(END_POINT + "/output-stock-time", {params: {start: startDate}});

    const auditZeroInventoryCost = (startDate) => Vue.axios.get(END_POINT + "/zero-cost-inventory", {params: {start: startDate}});

    const auditOuputNotStocked = (startDate) => Vue.axios.get(END_POINT + "/not-stocked-output", {params: {start: startDate}});

    const auditPurchaseNotStocked = (startDate) => Vue.axios.get(END_POINT + "/not-stocked-purchase", {params: {start: startDate}});

    const auditAdjustmentOverSpread = (startDate) => Vue.axios.get(END_POINT + "/spread-over-adjustment", {params: {start: startDate}});

    const auditStockedPurchaseItemCostMismatch = (startDate) => Vue.axios.get(END_POINT + "/stocked-purchase-item-price-mismatch", {params: {start: startDate}});

    const auditInventoryItemDoubleSold = (startDate) => Vue.axios.get(END_POINT + "/double-sold-inventory-item", {params: {start: startDate}});

    const auditRemovedInventoryInUse = (startDate) => Vue.axios.get(END_POINT + "/removed-inventory-in-use", {params: {start: startDate}});

    const auditNegativeBalanceInventories = (startDate) => Vue.axios.get(END_POINT + "/nagative-balance-inventories", {params: {start: startDate}});

    export {
      auditPurchaseStockTime,
      auditInputOutOfRange,
      auditOutputOutOfRange,
      auditOutputStockTimeMismatch,
      auditZeroInventoryCost,
      auditOuputNotStocked,
      auditPurchaseNotStocked,
      auditAdjustmentOverSpread,
      auditStockedPurchaseItemCostMismatch,
      auditInventoryItemDoubleSold,
      auditRemovedInventoryInUse,
      auditNegativeBalanceInventories
    };
    